

import React, { useState, useEffect } from 'react';
import "../assets/css/ToggleSwitch.css";

const ToggleSwitch = ({ functionalityId, isEnabled, onToggle }) => {
  const [enabled, setEnabled] = useState(isEnabled);

  useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled]);

  const handleToggle = () => {
    const newState = !enabled; // Toggle the state
    setEnabled(newState); // Update local state
    onToggle(functionalityId, newState ? 'A' : 'X'); // Call the callback with new state
  };

  return (
    <label className="switch" title={!enabled?"Functionality has been disabled permanently":""}>
      <input type="checkbox" checked={enabled} onChange={handleToggle} disabled={!enabled} />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
