import React, { useEffect, useState } from "react";
import { withAxiosHOC } from "../hoc/withAxiosHOC"
import { WidthProvider, Responsive } from "react-grid-layout";

import _ from "lodash";
import "../assets/css/styles.css";
import "../assets/css/example-styles.css";
import style from "../assets/css/Payload.module.css";
import useAxios from "../hooks/useAxios";

// AT-2072 | Vaibhav Sawant | 10-08-2023 | Drag and Drop Design Tile Component

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

class PayloadTile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
    };

    this.state = {
      items: [0, 1].map(function(i, key, list) {
        return {
          i: i.toString(),
          x: i,
          y: 0,
          w: 1,
          h: 2,
          fieldname: "",
          fielddata: "",
        };
      }),
      newCounter: 1,
      headerLayout: "",
      bodyele: [0, 1].map(function(i, key, list) {
        return {
          i: "b" + i.toString(),
          x: i == 0 ? i : i + 1,
          y: 0,
          w: 2,
          h: 2,
          fieldname: "",
          fielddata: "",
        };
      }),
      newCounterBody: 1,
      bodyLayout: "",
      fieldlist: [],
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onAddBodyItem = this.onAddBodyItem.bind(this);
    this.saveDesign = this.saveDesign.bind(this);
    this.onFieldSelect = this.onFieldSelect.bind(this);
    this.fetchAPIData = this.fetchAPIData.bind(this);

    // let apitoken = localStorage.getItem("api_token");
    // fetch(process.env.REACT_APP_BASE_URL + "/get/webhook/configuration/data", {
    //     method: "GET",
    //     headers: { "api-token": apitoken }
    // })
    //     .then((res) => {
    //         return res.json();
    //     }).then((json) => {
    //         // console.log(json);
    //         this.state.component_mapping = JSON.parse(json.component_mapping);
    //     })

    console.log("this.props.data", this.props.data);

    this.state.fieldlist = this.props.data.fieldslist;
    this.state.effective_designation_id = this.props.data.effective_designation_id;
    this.state.bucket_name = this.props.data.bucket_name;
    this.state.event_name = this.props.data.event_name;
    this.state.component_mapping = this.props.component_mapping;
    this.state.flag = this.props.data.flag;
    // this.fetchAPIData(this.props.data.view_id);
    //(this.props.data.component_mapping !== undefined) ? JSON.parse(this.props.data.component_mapping) : "";
    // console.log(JSON.parse(this.state.fieldlist));
    // this.state.fieldlist.map((field) => {
    //     console.log(field);
    // });

    console.log("this.state.fieldlist", this.props.component_mapping);

    {
      /* AT-2072 | Vaibhav Sawant | 06-09-2023 | Loading Data as create or view request */
    }
    if (this.state.component_mapping == "" && this.state.flag == "V") {
      if (this.props.data.component_mapping != null) {
        this.fetchAPIData(this.props.data.view_id, 0);
      }
      let cmp = JSON.parse(this.props.data.component_mapping);

      // console.log(cmp);

      if (cmp != null) {
        this.state.items = cmp.header;
        this.state.bodyele = cmp.body;

        let max_num = 0;
        cmp.header.map((item) => {
          if (parseInt(item.i) > max_num) {
            max_num = item.i;
          }
        });
        this.state.newCounter = max_num;
        max_num = 0;
        cmp.body.map((item) => {
          if (parseInt(item.i.split("b")[1]) > max_num) {
            max_num = item.i.split("b")[1];
          }
        });
        this.state.newCounterBody = max_num;

        // this.state.newCounterBody = (cmp.body.length) - 1;
      } else {
        this.state.newCounter = 1;
        this.state.newCounterBody = 1;
      }
      // console.log(this.state.newCounter);
      // console.log(this.state.newCounterBody);
    } else {
      console.log("component_mapping", this.state.component_mapping);
      let cmp = this.state.component_mapping;

      if (this.state.flag !== "C" && cmp) {
        this.state.items = cmp.header;
        this.state.bodyele = cmp.body;
        this.state.newCounter =
          this.state.flag == "C" ? 1 : cmp.header.length - 1;
        this.state.newCounterBody =
          this.state.flag == "C" ? 1 : cmp.body.length - 1;
      }

      console.log(this.state.items);
    }
    // console.log(this.state.newCounter);
    // console.log(this.state.newCounterBody);
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  fetchAPIData(view_id, flag) {
    let apitoken = localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : localStorage.getItem("api_token");
    this.props.sendRequest({
      url: process.env.REACT_APP_BASE_URL + "/get/webhook/configuration/data",
      method: "GET",
      headers: { "api-token": apitoken },
    })
      .then((json) => {
        // console.log(json);
        json.map((item) => {
          if (item["id"] == view_id) {
            if (item.component_mapping != null) {
              this.state.component_mapping = JSON.parse(item.component_mapping);
            } else {
              this.state.component_mapping = "";
            }

            // console.log(this.state.component_mapping.header);
          }
        });
        // console.log(this.state.component_mapping);
        // console.log(this.state.items);

        if (
          this.state.component_mapping != null ||
          this.state.component_mapping != "" ||
          this.state.component_mapping != "null"
        ) {
          this.state.items = this.state.component_mapping.header;
          this.state.bodyele = this.state.component_mapping.body;
          let max_num = 0;
          this.state.component_mapping.header.map((item) => {
            if (parseInt(item.i) > max_num) {
              max_num = item.i;
            }
          });
          this.state.newCounter = max_num;
          max_num = 0;
          this.state.component_mapping.body.map((item) => {
            if (parseInt(item.i.split("b")[1]) > max_num) {
              max_num = item.i.split("b")[1];
            }
          });
          this.state.newCounterBody = max_num;
          // this.state.newCounter = (this.state.component_mapping == "") ? 1 : (this.state.component_mapping.header.length - 1);
          // this.state.newCounterBody = (this.state.component_mapping == "") ? 1 : (this.state.component_mapping.body.length - 1);
          // console.log(this.state.newCounter);
          // console.log(this.state.newCounterBody);
        }
        // this.forceUpdate();

        if (flag) {
          alert("Saved Successfully!");
          this.props.create_view(this.state.component_mapping);
        }
        // this.state.component_mapping = JSON.parse(json.component_mapping);
      });
  }

  static get defaultProps() {
    return {
      // className: "layout",
      cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
      rowHeight: 30,
      isDraggable: true,
      isResizable: true,
    };
  }

  onAddItem() {
    /*eslint no-console: 0*/
    // console.log(this.state.newCounter);
    // return;

    console.log("adding", "n" + this.state.newCounter);
    console.log(this.state.items);

    this.state.items.concat({
      i: (++this.state.newCounter).toString(),
      x: (this.state.items.length * 2) % (this.state.cols || 12),
      y: Infinity, // puts it at the bottom
      w: 1,
      h: 2,
      fieldname: "",
      fielddata: "",
    });
    console.log(this.state.items);
    // return;
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: (++this.state.newCounter).toString(),
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 1,
        h: 2,
        fieldname: "",
        fielddata: "",
      }),
      // Increment the counter to ensure key is always unique.
      // newCounter: this.state.newCounter + 1
    });
    console.log(this.state.items);
  }

  onAddBodyItem() {
    /*eslint no-console: 0*/
    this.setState({
      // Add a new item. It must have a unique key!
      bodyele: this.state.bodyele.concat({
        i: "b" + (++this.state.newCounterBody).toString(),
        x: (this.state.bodyele.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 2,
        h: 2,
        fieldname: "",
        fielddata: "",
      }),
      // Increment the counter to ensure key is always unique.
      // newCounterBody: this.state.newCounterBody + 1
    });
  }

  saveDesign() {
    let hflag = 0;

    this.state.items.map((item) => {
      if (item["fieldname"] === "") {
        hflag++;
      }
    });

    if (hflag == this.state.items.length) {
      alert("Please Select Atleast one field in Header");
      return false;
    } else if (hflag > 0) {
      alert("Please Select Field or Delete Empty Blocks in Header");
      return false;
    }

    let bflag = 0;
    this.state.bodyele.map((item) => {
      if (item["fieldname"] === "") {
        bflag++;
      }
    });

    if (bflag == this.state.bodyele.length) {
      alert("Please Select Atleast one field in body");
      return false;
    } else if (bflag > 0) {
      alert("Please Select Field or Delete Empty Blocks in Body");
      return false;
    }

    if (hflag == 0 && bflag == 0) {
      let finalArray = { header: this.state.items, body: this.state.bodyele };

      //if (this.state.flag == "V") {
      let apitoken = localStorage.getItem("access_token")
        ? localStorage.getItem("access_token")
        : localStorage.getItem("api_token");
      const formdata = new FormData();
      formdata.append("view_id", this.props.data.view_id);
      formdata.append("component_mapping", JSON.stringify(finalArray));
      //formdata.append("flag", "CPM");
      this.props.sendRequest({
        url: process.env.REACT_APP_BASE_URL + "/store/webhook/configuration",
        data: formdata,
        method: "POST",
        headers: { "api-token": apitoken },
      })
        .then((json) => {
          // this.state.component_mapping = finalArray;
          console.log("data - ", json);
          this.fetchAPIData(this.props.data.view_id, 1);

          // this.forceUpdate();
          // window.location.reload();
          // console.log(this.state.component_mapping);
          //   if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          //     localStorage.clear();
          //     this.props.history.push("/");
          //   } else if (json.length == 0) {
          //     this.setState({
          //       isSpin: false,
          //       message: "No Data Found!",
          //       isErrOpen: true
          //     });
          //   } else {

          //     if (json.status == 0) {
          //       this.setState({
          //         isSpin: false,
          //         errMessage: json.message,
          //         errFlag: true
          //       });
          //     } else {
          //       this.state.loanModal = false;
          //       this.forceUpdate();
          //       this.setState({
          //         isSpin: false,
          //         messageNew: json.message,
          //         isErrOpenNew: true
          //       });

          //     }

          //   }
        })
        .catch((error) => console.log("error", error));
      // } else {
      //   this.props.create_view(finalArray);
      //   return;
      // }
    }
  }

  onFieldSelect(e) {
    console.log(e.target.id);
    let temp = JSON.parse(e.target.value);
    // console.log(e.target.id.split("_")[0].indexOf("b"));

    let flag = 0;

    this.state.items.map((item) => {
      if (item["fieldname"] === temp.BucketField) {
        flag = 1;
      }
    });

    this.state.bodyele.map((item) => {
      if (item["fieldname"] === temp.BucketField) {
        flag = 1;
      }
    });

    console.log(flag);

    if (flag == 1) {
      alert("Already Selected!");
      e.target.value = "";
    } else {
      if (e.target.id.split("_")[0].indexOf("b") < 0) {
        this.state.items.map((item, index) => {
          console.log(item["i"]);
          if (item["i"] === e.target.id.split("_")[0]) {
            console.log(temp.BucketField);
            this.state.items[index]["fieldname"] = temp.BucketField;
            this.state.items[index]["fielddata"] = e.target.value;
          }
        });
      } else {
        this.state.bodyele.map((item, index) => {
          if (item["i"] === e.target.id.split("_")[0]) {
            this.state.bodyele[index]["fieldname"] = temp.BucketField;
            this.state.bodyele[index]["fielddata"] = e.target.value;
          }
        });
      }

      console.log(this.state.items);
    }
  }

  createElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
    };
    const i = el.add ? "+" : el.i;
    const fvalue = JSON.stringify(el.fielddata);
    const fname = el.fieldname;
    // console.log(el);
    return (
      <div key={i} data-grid={el}>
        {el.add ? (
          <span
            className="add text"
            onClick={this.onAddItem}
            title="You can add an item by clicking here, too."
          >
            Add +
          </span>
        ) : (
          <>
            {fname !== "" ? (
              <span className="text">{"<" + fname + ">"}</span>
            ) : (
              <select
                className="text"
                id={i + "_headerSelect"}
                onChange={this.onFieldSelect}
                onClick={this.props.changeA}
              >
                <option value="">Select API Field</option>
                {this.state.fieldlist &&
                  this.state.fieldlist.map((field) => (
                    <option value={JSON.stringify(field)}>
                      {field.fieldname}
                    </option>
                  ))}
              </select>
            )}

            {/* <span className="text" id={i} >{i}</span> */}
            {/* <input type='text' id={i + "_input"} placeholder="Enter Label" ref={myinput => (this.input = myinput)} />  */}
          </>
        )}
        <span
          className="remove"
          style={removeStyle}
          onClick={this.onRemoveItem.bind(this, i)}
        >
          x
        </span>
      </div>
    );
  }

  createBodyElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
    };
    const i = el.add ? "+" : el.i;
    let fvalue = el.fielddata;
    let fname = el.fieldname;
    return (
      <div key={i} data-grid={el}>
        {el.add ? (
          <span
            className="add text"
            onClick={this.onAddItem}
            title="You can add an item by clicking here, too."
          >
            Add +
          </span>
        ) : (
          <>
            {fname == "" ? (
              <select
                className="text"
                id={i + "_headerSelect"}
                onChange={this.onFieldSelect}
              >
                <option value="">Select API Field</option>
                {this.state.fieldlist &&
                  this.state.fieldlist.map((field) => (
                    <option value={JSON.stringify(field)}>
                      {field.fieldname}
                    </option>
                  ))}
              </select>
            ) : (
              <span className="text">{"<" + fname + ">"}</span>
            )}

            {/* <span className="text" id={i} >{i}</span> */}
            {/* <input type='text' id={i + "_input"} placeholder="Enter Label" ref={myinput => (this.input = myinput)} />  */}
          </>
        )}
        <span
          className="remove"
          style={removeStyle}
          onClick={this.onRemoveBodyItem.bind(this, i)}
        >
          x
        </span>
      </div>
    );
  }

  onLayoutChange(layout, layouts) {
    console.log(this.state.newCounter);
    console.log(this.state.newCounterBody);
    this.state.headerLayout = layout;
    this.state.headerLayout.map((item, index) => {
      this.state.items[index]["x"] = item["x"];
      this.state.items[index]["y"] = item["y"];
      this.state.items[index]["w"] = item["w"];
      this.state.items[index]["h"] = item["h"];
    });
    // console.log(layout);
    // console.log(layouts.lg);
    // if (layout.length > 0) {
    //     this.state.headerLayout = layout;
    //     this.state.headerLayout.map((item, index) => {
    //         this.state.items[index]["x"] = item["x"];
    //         this.state.items[index]["y"] = item["y"];
    //         this.state.items[index]["w"] = item["w"];
    //         this.state.items[index]["h"] = item["h"];
    //     });
    // } else {
    //     layout = layouts.lg;
    //     // this.state.headerLayout = layout;

    //     this.state = {
    //         items: [0, 1].map(function (i, key, list) {
    //             return {
    //                 i: i.toString(),
    //                 x: i,
    //                 y: 0,
    //                 w: 1,
    //                 h: 2,
    //                 fieldname: "",
    //                 fielddata: ""
    //             };
    //         }),
    //         newCounter: 1,
    //         headerLayout :layout,
    //         bodyele: [0, 1].map(function (i, key, list) {
    //             return {
    //                 i: "b" + i.toString(),
    //                 x: (i == 0) ? (i) : (i + 1),
    //                 y: 0,
    //                 w: 2,
    //                 h: 2,
    //                 fieldname: "",
    //                 fielddata: ""
    //             };
    //         }),
    //         newCounterBody: 1,
    //         bodyLayout: "",
    //         fieldlist: []
    //     };
    //     console.log(this.state.headerLayout);
    //     this.state.headerLayout.map((item, index) => {
    //         this.state.items[index]["x"] = item["x"];
    //         this.state.items[index]["y"] = item["y"];
    //         this.state.items[index]["w"] = item["w"];
    //         this.state.items[index]["h"] = item["h"];
    //     });

    //     // console.log(this.state.items);

    // }
    // saveToLS("layouts", layouts);
    // console.log(this.state.items);

    // console.log(layout);
    this.setState({ layouts, layout });
  }

  onLayoutChangeBody(layout, layouts) {
    // console.log(layout);
    this.state.bodyLayout = layout;
    this.state.bodyLayout.map((item, index) => {
      this.state.bodyele[index]["x"] = item["x"];
      this.state.bodyele[index]["y"] = item["y"];
      this.state.bodyele[index]["w"] = item["w"];
      this.state.bodyele[index]["h"] = item["h"];
    });
  }

  onRemoveItem(i) {
    console.log("removing", i);
    this.setState({ items: _.reject(this.state.items, { i: i }) });
  }

  onRemoveBodyItem(i) {
    console.log("removing", i);
    this.setState({ bodyele: _.reject(this.state.bodyele, { i: i }) });
  }

  render() {
    return (
      <>
        <div className="row">
          <button
            className="btn btn-primary mr-2 ml-4"
            onClick={this.onAddItem}
          >
            Add Item
          </button>

          <button className="btn btn-primary" onClick={this.onAddBodyItem}>
            Add Body Item
          </button>
        </div>
        <br />
        <div style={{ alignSelf: "center" }}>
          <div style={{ width: "480px" }}>
            {/* <button onClick={() => this.resetLayout()}>Reset Layout</button> */}

            <ResponsiveReactGridLayout
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={30}
              layouts={this.state.layouts}
              onLayoutChange={(layout, layouts) =>
                this.onLayoutChange(layout, layouts)
              }
            >
              {_.map(this.state.items, (el) => this.createElement(el))}
              {/* <div key="1" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">1</span>
                    </div>
                    <div key="2" data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">2</span>
                    </div>
                    <div key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">3</span>
                    </div>
                    <div key="4" data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">4</span>
                    </div>
                    <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">5</span>
                    </div> */}
            </ResponsiveReactGridLayout>

            <ResponsiveReactGridLayout
              className="pv-block-body"
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={30}
              layouts={this.state.layouts}
              onLayoutChange={(layout, layouts) =>
                this.onLayoutChangeBody(layout, layouts)
              }
              isResizable={true}
              isDraggable={true}
            >
              {_.map(this.state.bodyele, (el) => this.createBodyElement(el))}
            </ResponsiveReactGridLayout>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-center mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.saveDesign}
          >
            Save Design
          </button>
        </div>
        {/* <button type="button" className="btn btn-primary" onClick={(this.state.flag === "C") ? () => this.props.create_view(this.state.items, this.state.bodyele) : this.saveDesign}>Save Design</button> */}
      </>
    );
  }
}

// PayloadTile.propTypes = {
//     // headers: PropTypes.element.isRequired,
//     // main: PropTypes.element.isRequired,
//     header: PropTypes.element,

//     // footer: PropTypes.element
// };
export default withAxiosHOC(PayloadTile)