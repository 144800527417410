// src/components/ElementInfoModal.js
import { Result } from 'antd';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ElementInfoModal = ({ show, onHide, result }) => {
   
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Functionality status</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
      
     

       {
  Object.values(result).map(k => (
    <p>{k}</p>
  ))
}
     
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementInfoModal;
