// // src/components/ResultsModal.js
// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import "../assets/css/Element_permissions.css";

// const ResultsModal = ({ show, onHide, results }) => {
//   return (

//     <Modal show={show} onHide={onHide}>
//       <Modal.Header closeButton>
//         <Modal.Title>Users</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {console.log(results)}

//         {results.length > 0 ? (

//              <table className="results-table">
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Username</th>

//             </tr>
//           </thead>
//           <tbody>
//             {results.map((result, index) => (
//               <tr key={index}>
//                  {/* <p><span className='left'><strong>Name: </strong>{result && result.first_name} </span>&nbsp;&nbsp;<span className='right'><strong>Username: </strong>{result &&  result.username}</span></p>*/}

//               <td>{result.first_name}</td>
//               <td> {result.username}</td>

//               </tr>

//             ))}

//           </tbody>
//         </table>
//         ) : (
//           <p>No users found.</p>
//         )}
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ResultsModal;

// src/components/ResultsModal.js
import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import loader from "../assets/images/loader.gif";
import useAxios from "../hooks/useAxios";
import "../assets/css/Element_permissions.css";
import "../assets/css/ResultModal.css";

const ResultsModal = ({ show, onHide, results }) => {
  // State to store the selected filter option
  const [loading, sendRequest] = useAxios();
  const [filter, setFilter] = useState("all");
  const [functionalityId, setFunctionalityId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [Users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [modalInfo, setModalInfo] = useState({});
  const [isSpin, setisSpin] = useState(false);
  const [isAlerted, setIsAlerted] = useState("0");

  // State to store the enabled/disabled status of each user (by index)
  const [enabledUsers, setEnabledUsers] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedEndTimeStamp, setSelectedEndTimeStamp] = useState("");
  const [selectedStartTimeStamp, setSelectedStartTimeStamp] = useState("");
  const [toBeEnabledUsers, setToBeEnabledUsers] = useState({});
  const [toBeDisabledUsers, setToBeDisabledUsers] = useState({});
  const [userData, setUserData] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  let alertTriggered = false;
  const users = results.users || [];
  const fId = results.functionality_id || "";
  const currentTimestamp = new Date().getTime();
  console.log("fid" + fId);
  const [divKey, setDivKey] = useState(0);

  const reloadDiv = () => {
    setDivKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setFunctionalityId(fId);
    setUsers(results.users);
    setAllUsers(results.users);
    setUserData({});
    Users &&
      Users.map((result, index) =>
        setEnabledUsers((prevStatus) => ({
          ...prevStatus,
          [result.userid]:
            result.end_timestamp &&
            !currentTimestamp <
            new Date(result.end_timestamp.replace(" ", "T")).getTime(),
        }))
      );

    console.log(enabledUsers);
    const fetchPermissionsForFunctionality = (functionalityId) => {
      try {
        setisSpin(true);

        const form = new FormData();

        form.append("flag", "get_permission_by_functionality");
        form.append("functionality_id", functionalityId);

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);

        sendRequest(
          {
            url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
            method: "POST",
            data: form,
            headers: {
              // 'Content-Type': 'application/json',
              "api-token": apitoken,
            },
          },
          (response) => {
            console.log(response);
            setisSpin(false);
            setPermissions(response.permissions);

            return response;
          }
        );
      } catch (error) {
        setisSpin(false);
        console.error("Error updating permissions:", error);
      }
    };

    setPermissions([]);
    setPermissionId("");

    if (fId) {
      fetchPermissionsForFunctionality(fId);
    }

    setShowConfirmationModal(false);
    setSelectedEndTimeStamp('');
    setSelectedStartTimeStamp('');
    setIsAlerted("0");
  }, [show]);

  const disableUserFunctionality = async (functionalityId, permissionId) => {
    console.log(userData);

    var isValid = true;
    Object.keys(userData).forEach((key) => {
      if (!("start_timestamp" in userData[key])) {
        if (userData[key].end_timestamp == null) {
          isValid = false;
        }
      }
    });

    const userArray = [];

    Object.keys(userData).forEach((key) => {
      if (!("start_timestamp" in userData[key])) {
        userArray.push(userData[key]);
      }
    });

    if (isValid && userArray.length > 0) {
      try {
        setisSpin(true);
        const form = new FormData();

        form.append("flag", "disable_functionality_for_users");
        form.append("functionality_id", functionalityId);
        form.append("permission_id", permissionId);

        console.log(userArray);

        setShowConfirmationModal(true);

        form.append("users", JSON.stringify(JSON.stringify(userArray)));

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);
        setPermissionId(permissionId);
        setModalInfo({
          title: "Functionality Status",
          message: "Functionality has been updated for all the selected users",
        });

        //setShowConfirmationModal(true);

        isValid &&
          userArray.length > 0 &&
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
              method: "POST",
              data: form,
              headers: {
                // 'Content-Type': 'application/json',
                "api-token": apitoken,
              },
            },

            (response) => {
              setisSpin(false);

              setApiMessage(
                "Functionality has been updated for all the selected users"
              );
              console.log(response);
              fetchUsersForFunctionalityAndPermission(
                functionalityId,
                permissionId
              );
            },

            (error) => {
              //setShowConfirmationModal(true);

              // setApiMessage(error);
              console.log("Error in API request", error);
            }
          );
      } catch (error) {
        setApiMessage(error);

        setisSpin(false);

        console.log("Error updating permissions:", error);
      }
    } else if (isValid == false) {
      // if (isAlerted === "0") {
      //   alert("Please enter the dates correctly");
      //   setIsAlerted("1");
      // }

      if(alertTriggered==false)
      {
        alert("Please enter the dates correctly");
        alertTriggered=true;
      }

      console.log(isAlerted);
      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      setShowConfirmationModal(false);
      console.log(isAlerted);

    }
  };

  const enableUserFunctionality = async (functionalityId, permissionId) => {
    console.log(userData);

    var isValid = true;
    Object.keys(userData).forEach((key) => {
      if ("start_timestamp" in userData[key]) {
        if (
          userData[key].start_timestamp == null ||
          userData[key].end_timestamp == null
        ) {
          isValid = false;
        }
      }
    });

    const userArray = [];

    Object.keys(userData).forEach((key) => {
      if ("start_timestamp" in userData[key]) {
        userArray.push(userData[key]);
      }
    });

    if (isValid && userArray.length > 0) {
      try {
        setisSpin(true);
        const form = new FormData();

        form.append("flag", "enable_functionality_for_users");
        form.append("functionality_id", functionalityId);
        form.append("permission_id", permissionId);

        console.log(userArray);

        setShowConfirmationModal(true);

        form.append("users", JSON.stringify(JSON.stringify(userArray)));

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);
        setPermissionId(permissionId);
        setModalInfo({
          title: "Functionality Status",
          message: "Functionality has been updated for all the selected users",
        });

        //setShowConfirmationModal(true);

        isValid &&
          userArray.length > 0 &&
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
              method: "POST",
              data: form,
              headers: {
                // 'Content-Type': 'application/json',
                "api-token": apitoken,
              },
            },

            (response) => {
              setisSpin(false);

              setApiMessage(
                "Functionality has been updated for all the selected users"
              );
              console.log(response);
              fetchUsersForFunctionalityAndPermission(
                functionalityId,
                permissionId
              );
            },

            (error) => {
              //setShowConfirmationModal(true);

              // setApiMessage(error);
              console.log("Error in API request", error);
            }
          );
      } catch (error) {
        setApiMessage(error);

        setisSpin(false);

        console.log("Error updating permissions:", error);
      }
    } else if (isValid == false) {
      console.log(showConfirmationModal);
      setShowConfirmationModal(false);
      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      console.log(showConfirmationModal);
      setShowConfirmationModal(false);
      console.log(isAlerted);
      
      // if (isAlerted === "1") 
      // {
      //      return;
      // }

      // if (isAlerted === "0") {
      //   alert("Please enter the dates correctly");
      //   setIsAlerted("1");

      // }

      if(alertTriggered==false)
        {
          alert("Please enter the dates correctly");
          alertTriggered=true;
        }
  


      setShowConfirmationModal(false);
    }
  };

  // const enableUserFunctionality = async (functionalityId, permissionId) => {

  //   var isValid=true;
  //   Object.keys(userData).forEach(key => {

  //     if (('start_timestamp' in userData[key])) {
  //       console.log("heeeeey"+userData[key].start_timestamp);

  //       if(userData[key].start_timestamp==null || userData[key].end_timestamp==null)
  //       {

  //           isValid=false;
  //       }

  //     }
  //   });

  //   if(isValid)
  //   {

  //    try
  //   {

  //     setisSpin(true);

  //     const form = new FormData();

  //     form.append("flag", "enable_functionality_for_users")
  //     form.append("functionality_id", functionalityId)
  //     form.append("permission_id", permissionId)
  //     const userArray = []

  //     Object.keys(userData).forEach(key => {

  //       if (('start_timestamp' in userData[key])) {
  //         userArray.push(userData[key]);

  //       }
  //     });

  //     console.log(userArray);
  //     setShowConfirmationModal(true);
  //     var temp = {};

  //     form.append("users", JSON.stringify(JSON.stringify(userArray)))

  //     let apitoken = localStorage.getItem("api_token");

  //     setFunctionalityId(functionalityId);
  //     setPermissionId(permissionId);

  //     setModalInfo({
  //       title: 'Functionality Status',
  //       message: "Functionality has been updated for all the selected users",

  //     });

  //     isValid && userArray.length > 0 && sendRequest(
  //       {
  //         url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
  //         method: "POST",
  //         data: form,
  //         headers: {

  //          // 'Content-Type': 'application/json',
  //           "api-token": apitoken,
  //         },

  //       },
  //       (response) => {
  //         temp=response;

  //         setApiMessage("Functionality has been updated for all the selected users");
  //         //setShowConfirmationModal(true);
  //         setisSpin(false);
  //         fetchUsersForFunctionalityAndPermission(functionalityId,permissionId);
  //         console.log(response);

  //         return response
  //       },

  //       // (error) => {

  //       //   //setShowConfirmationModal(true);
  //       //   setApiMessage(error);
  //       //   console.error("Error in API request", error);
  //       // }

  //       (error) => {
  //         //setShowConfirmationModal(true);
  //         //setApiMessage("Error in updating the functionality");
  //         console.log("Error in API request", error);

  //       }

  //     );

  //     //setisSpin(false);

  //   }

  //   catch (error) {

  //     //setShowConfirmationModal(true);
  //     //setApiMessage("Error in updating the functionality");
  //     console.log("Error in API request", error);

  //   }

  // }

  // else
  // {
  //   fetchUsersForFunctionalityAndPermission(functionalityId,permissionId);
  //   setShowConfirmationModal(false);
  //   alert("Please enter the dates correctly");

  // }

  // };

  const handleFunctionalityUpdateButton = () => {
    disableUserFunctionality(functionalityId, permissionId);
    console.log(isAlerted);
    enableUserFunctionality(functionalityId, permissionId);

    setisSpin(false);
    Users &&
      Users.map((result, index) =>
        setEnabledUsers((prevStatus) => ({
          ...prevStatus,
          [result.userid]:
            result.end_timestamp &&
            !currentTimestamp <
            new Date(result.end_timestamp.replace(" ", "T")).getTime(),
        }))
      );

    reloadDiv();

    setUserData({});
    console.log(modalInfo);
    setApiMessage("");


    fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
    alertTriggered=false;
    setIsAlerted("0");
    //setShowConfirmationModal(false);
  };

  const fetchUsersForFunctionalityAndPermission = (
    functionalityId,
    permissionId
  ) => {
    try {
      setisSpin(true);

      const form = new FormData();

      form.append("flag", "get_users_by_permission_and_functionality");
      form.append("functionality_id", functionalityId);
      form.append("permission_id", permissionId);

      console.log(permissionId);
      console.log(functionalityId);

      console.log(form);

      let apitoken = localStorage.getItem("api_token");

      setFunctionalityId(functionalityId);
      setPermissionId(permissionId);

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          setisSpin(false);
          console.log(response);
          setUsers(response.users);

          Users &&
            Users.map((result, index) =>
              setEnabledUsers((prevStatus) => ({
                ...prevStatus,
                [result.userid]:
                  result.end_timestamp &&
                  currentTimestamp <
                  new Date(result.end_timestamp.replace(" ", "T")).getTime(),
              }))
            );

          return response;
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
  };

  // Handle dropdown change
  const handlePermissionChange = (e) => {
    const filterValue = e.target.value;
    setPermissionId(filterValue);
    console.log("fun" + fId);
    console.log("perm" + filterValue);

    if (filterValue) {
      fetchUsersForFunctionalityAndPermission(fId, filterValue);
    } else {
      setUsers(allUsers);
    }
  };

  // Handle checkbox toggle for enabling/disabling functionality
  const handleCheckboxToggle = (userid, status) => {
    console.log(status);
    console.log(!status);

    setEnabledUsers((prevState) => ({
      ...prevState,
      [userid]: !enabledUsers[userid], // Toggle the current user's status
    }));

    var userDetails = {};

    if (status == true) {
      userDetails = {
        userid: userid,
        end_timestamp: formatDateTime(selectedEndTimeStamp),
      };
    } else if (status == false) {
      userDetails = {
        userid: userid,
        start_timestamp: formatDateTime(selectedStartTimeStamp),
        end_timestamp: formatDateTime(selectedEndTimeStamp),
      };
    }
    console.log(userDetails);

    setUserData((prevData) => ({
      ...prevData,
      [userDetails.userid]: userDetails,
    }));

    //setSelectedStartTimeStamp('');
    // setSelectedEndTimeStamp('');
  };

  const handleEndDatetimeChange = (event) => {
    setSelectedEndTimeStamp(event.target.value);
    console.log(event.target.value);
  };

  const handleStartDatetimeChange = (event) => {
    setSelectedStartTimeStamp(event.target.value);
    console.log(event.target.value);
  };

  const handleModalCancel = () => {
    setShowConfirmationModal(false);
    setApiMessage("");
    setSelectedStartTimeStamp("");
    setSelectedEndTimeStamp("");
    fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
  };

  const formatDateTime = (datetimeString) => {
    if (datetimeString == null || datetimeString === "") {
      return null;
    }

    const date = new Date(datetimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const formatDate = (dateTimeString) => {
    if (dateTimeString == null || dateTimeString === "") {
      return null;
    }

    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // const updateUser = (newUser) => {
  //   setUserData((prevData) => ({
  //     ...prevData,
  //     [newUser.userid]: newUser
  //   }));
  // };

  // Filter results based on the dropdown selection
  // const filteredResults = results.filter((result) => {
  //   if (filter === 'all') return true;
  //   return result.username.startsWith(filter); // Example: Filter by usernames starting with the selected filter
  // });

  return (
    <>
      {isSpin ? (
        <div className="preloader">
          <img className="w30" src={loader} />
        </div>
      ) : (
        ""
      )}
      <br />
      <br />

      <Modal show={showConfirmationModal} onHide={handleModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Functionality Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {apiMessage === "" ? (
            <p>Loading please wait ...</p>
          ) : (
            <p>{apiMessage}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalCancel}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={onHide}
        centered
        //style={{ width: "1200px" }}
        className=".modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            //console(results["functionality_id"])
          }
          {/* Dropdown for filtering
        <Form.Group controlId="filterDropdown">
          <Form.Label>Select Permission</Form.Label>
          <Form.Control as="select" value={"Select permission"} onChange={handlePermissionChange}>
            {permissions && permissions.length>0 &&  permissions.map((permission) => (
          <option key={permission.permission_id} value={permission.permission_id}>
            {permission.permission_name}
          </option>
        ))}
          </Form.Control>
        </Form.Group> */}

          <div className="dropdown">
            <label htmlFor="app">Permissions:</label>
            <select
              id="permissions"
              value={permissionId}
              onChange={handlePermissionChange}
            >
              <option value="">select permission</option>

              {permissions &&
                permissions.length > 0 &&
                permissions.map((permission) => (
                  <option
                    key={permission.permission_id}
                    value={permission.permission_id}
                  >
                    {permission.permission_name}
                  </option>
                ))}
            </select>
          </div>

          {Users && Users.length > 0 ? (
            <table className="results-table" width="100%" key={divKey}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Username</th>

                  <th hidden={!permissionId}>Start date</th>
                  <th hidden={!permissionId}>End date</th>
                  <th hidden={!permissionId}>Status</th>
                </tr>
              </thead>
              <tbody>
                {Users.map((result, index) => (
                  <tr
                    key={index}
                    title={
                      result.end_timestamp &&
                        currentTimestamp <
                        new Date(
                          result.end_timestamp.replace(" ", "T")
                        ).getTime()
                        ? "Functionality is enabled for this user"
                        : "Functionality is disabled for this user"
                    }
                  >
                    {console.log(enabledUsers)}
                    {/* 
                  { setEnabledUsers((prevStatus) => ({
                   ...prevStatus, 
                 [result.userid]: result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime(), 
                  }))}
                   */}
                    <td>{result.first_name}</td>
                    <td>{result.username}</td>

                    <td hidden={!permissionId}>
                      <p title="current start date">
                        <u>{formatDate(result.start_timestamp)}</u>
                      </p>
                      <input
                        hidden={
                          result.end_timestamp &&
                          currentTimestamp <
                          new Date(
                            result.end_timestamp.replace(" ", "T")
                          ).getTime()
                        }
                        type="datetime-local"
                        title="Enter the start date to enable"
                        onChange={handleStartDatetimeChange}
                      ></input>
                    </td>

                    <td hidden={!permissionId}>
                      <p title="current end date">
                        <u>{formatDate(result.end_timestamp)}</u>
                      </p>
                      <input
                        type="datetime-local"
                        title={
                          result.end_timestamp &&
                            currentTimestamp <
                            new Date(
                              result.end_timestamp.replace(" ", "T")
                            ).getTime()
                            ? "Enter the end date to disable"
                            : "Enter the end date to enable"
                        }
                        onChange={handleEndDatetimeChange}
                      ></input>
                    </td>

                    <td hidden={!permissionId}>
                      <Form.Check
                        type="checkbox" //disabled={(result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime() && !selectedEndTimeStamp) || (result.end_timestamp && currentTimestamp >=  new Date(result.end_timestamp.replace(" ", "T")).getTime() && (!selectedEndTimeStamp || !selectedStartTimeStamp)) }
                        // checked={result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime()}
                        checked={
                          enabledUsers[result.userid] != null
                            ? enabledUsers[result.userid]
                            : result.end_timestamp &&
                            currentTimestamp <
                            new Date(
                              result.end_timestamp.replace(" ", "T")
                            ).getTime()
                        }
                        onChange={() =>
                          handleCheckboxToggle(
                            result.userid,
                            result.end_timestamp &&
                            currentTimestamp <
                            new Date(
                              result.end_timestamp.replace(" ", "T")
                            ).getTime()
                          )
                        }
                      // label={result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime()  ? "Enabled" : "Disabled"}
                      />
                    </td>
                  </tr>
                ))}

                <tr hidden={!permissionId}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr hidden={!permissionId}>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <Button
                      variant="secondary"
                      onClick={handleFunctionalityUpdateButton}
                      disabled={Object.keys(userData).length === 0}
                    >
                      Update
                    </Button>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <p></p>
              <p>No users found.</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResultsModal;
