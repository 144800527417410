import React, { useState, useEffect } from "react";
import "../assets/css/Element_permissions.css";
import "../assets/css/ResultModal.css";
import useAxios from "../hooks/useAxios";

import "antd/dist/antd.css";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import loader from "../assets/images/loader.gif";
import Commonheader from "./Commonheader";
import { Select } from "antd";
import ResultsModal from "./ResultsModal";
import ElementInfoModel from "./ElementInfoModel";
import { Modal, Button } from "react-bootstrap";
import StatusModel from "./StatusModel";
import ToggleSwitch from "./ToggleSwitch";

const App = (props) => {
  const [loading, sendRequest] = useAxios();
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedComponentId, setSelectedComponentId] = useState("");
  const [selectedElement, setSelectedElement] = useState("");
  const [components, setComponents] = useState([]);
  const [functionalities, setFunctionalities] = useState([]);
  const [functionalitiesCopy, setFunctionalitiesCopy] = useState([]);
  const [functionalitiesCopy1, setFunctionalitiesCopy1] = useState([]);
  const [
    functionalitiesCopyForUsers,
    setFunctionalitiesCopyForUsers,
  ] = useState([]);
  const [elementPermissions, setElementPermissions] = useState({});
  const [isSpin, setisSpin] = useState(false);
  const [selectedFunctionId, setSelectedFunctionId] = useState("");
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showStatusModal, setShowStatusModel] = useState(false);
  const [apiResults, setApiResults] = useState([]);
  const [apiStatus, setApiStatus] = useState({});
  const [showModal, setShowModal] = useState(false); // Modal state
  const [modalInfo, setModalInfo] = useState(null); // Modal info state
  const [userCount, setUserCount] = useState(null);
  const [userNumber, setUserNumber] = useState({});
  const [showElementInfoModal, setShowElementInfoModal] = useState(false);
  const [elementInfo, setElementInfo] = useState([]);
  const [elementInformation, setElementInformation] = useState({});
  const [filterElementId, setFilterElementId] = useState("");
  const [filterUserId, setFilterUsertId] = useState("");
  const [filteredFunctionalities, setFilteredFunctionalities] = useState([]);
  const [uniqueElements, setUniqueElements] = useState([]);
  const [uniqueElementMap, setUniqueElementMap] = useState({});
  const [userFunctionalitiesMap, setUserFunctionalitiesMap] = useState({});
  const [userResponse, setUserResponse] = useState({});
  const uniqueElementIds = new Set();
  var userFuncMap = {};
  const [functionalityStatus, setFunctionalityStatus] = useState("");
  const [render, setRender] = useState(0);

  const [isToggled, setIsToggled] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userButtonStatus, setUserButtonStatus] = useState(false);

  const [divKey, setDivKey] = useState(0);

  const reloadDiv = () => {
    setDivKey((prevKey) => prevKey + 1);
  };

  // const handleToggleChange = (event) => {
  //   const newToggleState = event.target.checked;

  //   // If toggling to the left (false), open the warning modal
  //   if (!newToggleState) {
  //     setShowWarningModal(true);
  //   } else {
  //     setIsToggled(true); // Directly set to true when switching to the right
  //   }
  // };

  const handleWarningModalConfirm = () => {
    console.log("I am getting called");
    setShowWarningModal(false); // Close warning modal
    setShowConfirmationModal(true); // Open confirmation modal
    setModalInfo({
      title: "Confirm Update",
      message: " Are you sure you want to continue ?",
      functionalityId: "",
    });
  };

  const handleConfirmationModalConfirm = () => {
    //setShowConfirmationModal(false); // Close confirmation modal
    // setIsToggled(false); // Keep the toggle off (left)
    updateFunctionality(selectedFunctionId, functionalityStatus);
    setShowWarningModal(false);
    setShowConfirmationModal(false);
    setFunctionalities(functionalitiesCopy);
    // reloadDiv();
  };

  const forceRender = () => {
    setRender((prev) => prev + 1); // This will trigger a re-render
  };

  const handleModalCancel = () => {
    setFunctionalities(functionalitiesCopy);

    setShowWarningModal(false);
    setShowConfirmationModal(false);
    reloadDiv();
    //window.location.reload();

    //fetchFunctionalities(selectedComponentId)
    //setIsToggled(true); // Revert toggle to the right
  };

  useEffect(() => {
    if (selectedApp) {
      fetchComponents(selectedApp);
    }
  }, [selectedApp]);

  useEffect(() => {
    if (selectedComponentId) {
      fetchFunctionalities(selectedComponentId);
      setUserFunctionalitiesMap({});
    }
  }, [selectedComponentId]);

  useEffect(() => {
    if (filterElementId) {
      setFilteredFunctionalities(functionalitiesCopy);
      // setFunctionalitiesCopyForUsers(functionalitiesCopy)
      const filtered = functionalitiesCopy.filter((func) =>
        func.element_permission.some(
          (element) => element.element_id === filterElementId
        )
      );

      setFunctionalities(filtered);
      setFunctionalitiesCopyForUsers(filtered);
      console.log(filteredFunctionalities);
      console.log(filterElementId);
      // handleFilterChange(filterElementId)
      // setFilterElementId(filterElementId)
      console.log(functionalities);
      //fetchFilteredElements(filterElementId)

      setFilterUsertId("");
    } else if (!filterElementId && !filterUserId) {
      setFunctionalities(functionalitiesCopy);
    }
  }, [filterElementId]);

  useEffect(() => {
    //const userFuncMap = {};

    // Fetch users for each functionality

    functionalities.map((func) => {
      const t = fetchUsersForFunctionality(func.functionality_id);

      console.log(t);

      // Map each user to the corresponding functionality

      //   if(!userResponse.message && userResponse.length>0)
      //   {
      //   userResponse.users.forEach(user => {
      //     if (userFuncMap[user.userid]) {
      //       userFuncMap[user.userid].push(func.functionality_id);
      //     } else {
      //       userFuncMap[user.userid] = [func.functionality_id];
      //     }
      //   });

      //   console.log(userResponse);
      //   setUserFunctionalityMap(userFuncMap);
      //   console.log(userFuncMap);

      //  }
    });
  }, [functionalitiesCopy]);

  useEffect(() => {
    if (filterUserId) {
      // Filter the functionalities based on the selected user ID
      console.log(filterUserId);
      const selectedFunctionalityIds =
        userFunctionalitiesMap[filterUserId] || [];

      const filtered = functionalitiesCopy1.filter((func) =>
        selectedFunctionalityIds.includes(func.functionality_id)
      );
      console.log(filtered);

      // const filtered = functionalitiesCopyForUsers.filter(func =>
      // selectedFunctionalityIds.includes(func.functionality_id)
      // );

      setFunctionalities(filtered);
      setFilterElementId("");
    } else if (!filterElementId && !filterUserId) {
      setFunctionalities(functionalitiesCopy1);
    }

    console.log(functionalities);
  }, [filterUserId]);

  const form = new FormData();

  const fetchFilteredElements = (filterValue) => {
    if (filterValue) {
      // Filter functionalities based on element ID
      const filtered = functionalities.filter((func) =>
        func.element_permission.some(
          (element) => element.element_id === filterValue
        )
      );

      setFunctionalities(filtered);

      setFilteredFunctionalities(functionalitiesCopy);
      console.log(filteredFunctionalities);
      console.log(functionalities);
    } else {
      // Show all functionalities if no filter is applied
      setFilteredFunctionalities(functionalitiesCopy);
    }
  };

  const fetchComponents = (app) => {
    form.append("app_name", app);
    form.append("flag", "fetch_component");

    let apitoken = localStorage.getItem("api_token");
    console.log(apitoken);

    setisSpin(true);

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "text/html",

          "api-token": apitoken,
        },
      },
      (response) => {
        setisSpin(false);

        setComponents(
          Array.isArray(response.components)
            ? response.components
            : [response.components]
        );
        console.log(response);
      }
    );
  };

  const fetchFunctionalities = (componentId) => {
    try {
      setisSpin(true);

      const form2 = new FormData();
      form2.append("flag", "fetch_functionality");
      form2.append("component_id", componentId);

      let apitoken = localStorage.getItem("api_token");

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form2,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          const data = response;
          setisSpin(false);

          const updatedFunctionalities = data.functionality.map((func) => ({
            ...func,
            isOpen: false,
          }));
          // console.log("CHecking the format "+updatedFunctionalities)
          console.log(updatedFunctionalities);

          const initialPermissions = {};
          updatedFunctionalities.forEach((func) => {
            initialPermissions[func.functionality_id] = {};
            func.element_permission.forEach((element) => {
              initialPermissions[func.functionality_id][element.element_id] =
                element.permission;
            });
          });
          setElementPermissions(initialPermissions);

          setFunctionalities(updatedFunctionalities);
          setFilteredFunctionalities(updatedFunctionalities);
          setFunctionalitiesCopy(updatedFunctionalities);
          setFunctionalitiesCopy1(updatedFunctionalities);

          const elementOccurrences = {};
          updatedFunctionalities.forEach((func) => {
            func.element_permission.forEach((element) => {
              if (!elementOccurrences[element.element_id]) {
                elementOccurrences[element.element_id] = [];
              }
              elementOccurrences[element.element_id].push(func.function_name);
            });
          });

          uniqueElementIds = new Set();

          console.log("Element occurrences:", elementOccurrences);
          setElementInformation(elementOccurrences);

          const allElements = updatedFunctionalities.flatMap(
            (func) => func.element_permission
          );

          // Remove duplicates based on element_id
          // const unique = {};
          // allElements.forEach(element => {
          //   if (!unique[element.element_id]) {
          //     unique[element.element_id] = element.element_name;
          //   }
          // });

          // setUniqueElementMap(unique)
          // console.log(unique)
          // console.log(uniqueElementMap)
          // console.log(functionalities)
        }
      );
    } catch (error) {
      console.error("Error fetching functionalities:", error);
    }
  };

  const handleAppChange = (e) => {
    setSelectedApp(e.target.value);
    setSelectedComponentId("");
    setFunctionalities([]);
    setElementPermissions({});
    setFilterElementId("");
    setFilteredFunctionalities([]);
  };

  const handleComponentChange = (e) => {
    setSelectedComponentId(e);
  };

  // const handleElementFilterChange = (e) => {
  //   setSelectedElement(e);

  // };

  const handleFilterChange = (e) => {
    const filterValue = e.target.value;
    setFilterElementId(filterValue);

    // if (filterValue) {
    //   // Filter functionalities based on element ID
    //   const filtered = functionalities.filter((func) =>
    //     func.element_permission.some((element) => element.element_id === filterValue)
    //   );

    //   setFunctionalities(filtered)

    //   setFilteredFunctionalities(functionalities);
    //   console.log(filteredFunctionalities);
    //   console.log(functionalities);
    // } else {
    //   // Show all functionalities if no filter is applied
    //   setFilteredFunctionalities(functionalities);

    // }
  };

  const handleUsersFilterChange = (e) => {
    const filterValue = e.target.value;
    setFilterUsertId(filterValue);
    //     const selectedFunctionalityIds = userFunctionalitiesMap[filterValue] || [];

    // const filteredFunctionalities = functionalities.filter(func =>
    //   selectedFunctionalityIds.includes(func.functionality_id)
    // );

    // setFunctionalities(filteredFunctionalities)
  };

  const handleOptionChange = (functionalityId, elementId, permission) => {
    setElementPermissions((prev) => ({
      ...prev,
      [functionalityId]: {
        ...prev[functionalityId],
        [elementId]: permission,
      },
    }));
  };

  const handleUpdatePermissions1 = (fid) => {
    try {
      setisSpin(true);
      const form4 = new FormData();

      form4.append("flag", "get_user_functionality");

      form4.append("functionality_id", fid);

      let apitoken = localStorage.getItem("api_token");

      setShowModal(true);
      setSelectedFunctionId(fid);
      setApiResults({});
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form4,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(response);

          //setUserNumber(response.users);

          // console.log(userNumber);

          setModalInfo({
            title: "Confirm Update",
            message: response.message
              ? " No user will be affected by this update , Do you want to continue ?"
              : response.users.length +
                " user(s) will be affected by this update , Do you want to continue ?",
            functionalityId: fid,
          });
          setisSpin(false);
          return response;

          setUserNumber({});
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
  };

  const handleElementInfoClick = (elementId) => {
    const info = functionalities
      .filter((func) =>
        func.element_permission.some(
          (element) => element.element_id === elementId
        )
      )
      .map((func) => func.function_name);

    setElementInfo(info);
    setShowElementInfoModal(true);
    // console.log(elementInformation[elementId])
    // alert("This element is present in the following functionalities : "+elementInformation[elementId])
  };

  const handleUpdatePermissions = (fid) => {
    try {
      setShowModal(false);

      const form3 = new FormData();

      const filteredFunctionalities = functionalities.filter(
        (func) => func.functionality_id === fid
      );

      const payload = filteredFunctionalities.map((func) => ({
        flag: "save_functionality",
        status: "A",
        component_id: selectedComponentId,
        element_permissions: Object.entries(
          elementPermissions[func.functionality_id]
        ).map(([elementId, permission]) => ({
          element_id: elementId,
          permission,
        })),
        function_name: func.function_name,
      }));

      payload.forEach((func) => {
        form3.append("flag", "save_functionality");
        form3.append("status", "A");
        form3.append("component_id", selectedComponentId);
        form3.append(
          "element_permissions",
          JSON.stringify(func.element_permissions)
        );
        form3.append("function_name", func.function_name);
      });

      let apitoken = localStorage.getItem("api_token");

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form3,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(response);
        }
      );
      // alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
    setSelectedFunctionId("");
  };

  const cancelUpdatePermissions = () => {
    setShowModal(false);
  };

  const handleFunctionalityButtonClick = (functionalityId) => {
    setSelectedFunctionId(functionalityId);
    // Trigger your API here
    console.log(`API triggered for functionality with id: ${functionalityId}`);

    try {
      const form4 = new FormData();

      form4.append("flag", "get_user_functionality");

      form4.append("functionality_id", functionalityId);

      let apitoken = localStorage.getItem("api_token");

      setisSpin(true);
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form4,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(response);
          const t = {};
          if (!response.message) {
            const temp = {};
            temp["functionality_id"] = functionalityId;
            temp["users"] = Array.isArray(response.users)
              ? response.users
              : [response.users];
            //setApiResults(Array.isArray(response.users) ? response.users : [response.users]);
            setApiResults(temp);
          }

          console.log(apiResults);
          setShowResultsModal(true);
          setUserCount(response.users.length);
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
    setSelectedFunctionId("");

    setisSpin(false);
  };

  const handleFunctionalityUpdate = async (functionalityId, status) => {
    setSelectedFunctionId(functionalityId);
    setFunctionalityStatus(status);

    setShowWarningModal(true);

    setModalInfo({
      title: "Confirm Update",
      message:
        " This will disable the functionality permanently , Do you want to continue ?",
      functionalityId: "",
    });
  };

  const updateFunctionality = async (functionalityId, status) => {
    // Trigger your API here

    setSelectedFunctionId(functionalityId);
    console.log(`API triggered for functionality with id: ${functionalityId}`);

    try {
      const form5 = new FormData();

      form5.append("status", status);
      form5.append("flag", "update_functionality_status");

      form5.append("functionality_id", functionalityId);

      console.log(form5);

      let apitoken = localStorage.getItem("api_token");

      //setShowModal(true)
      //setShowConfirmationModal(true)
      setShowWarningModal(true);

      //setShowConfirmationModal(true)

      setModalInfo({
        title: "Confirm Update",
        message:
          " This will disable the functionality permanently , Do you want to continue ?",
        functionalityId: "",
      });

      // setisSpin(true);
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form5,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(response);
          // setShowStatusModel(true)
          setApiStatus(response);
          console.log(apiStatus);
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
    // setSelectedFunctionId('');

    // setisSpin(false);
  };

  const fetchUsersForFunctionality = (fid) => {
    try {
      setisSpin(true);
      const form4 = new FormData();

      form4.append("flag", "get_user_functionality");

      form4.append("functionality_id", fid);

      let apitoken = localStorage.getItem("api_token");

      setSelectedFunctionId(fid);

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form4,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(response);
          if (!response.message && response.users.length > 0) {
            response.users.forEach((user) => {
              if (userFuncMap[user.username]) {
                userFuncMap[user.username].push(fid);
              } else {
                userFuncMap[user.username] = [fid];
              }
            });

            console.log(userFuncMap);
            //setUserFunctionalitiesMap(userFuncMap);

            setUserFunctionalitiesMap((prevData) => ({
              ...prevData,
              ...userFuncMap,
            }));
            console.log(userFunctionalitiesMap);

            setisSpin(false);
          }
          return response;

          //setUserNumber(response.users);

          // console.log(userNumber);
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
  };

  const fetchPermissionsForFunctionality = (fid) => {
    try {
      const form4 = new FormData();

      form4.append("flag", "get_permission_by_functionality");

      form4.append("functionality_id", fid);

      let apitoken = localStorage.getItem("api_token");

      setSelectedFunctionId(fid);

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form4,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(response);
          if (!response.message && response.users.length > 0) {
            response.users.forEach((user) => {
              if (userFuncMap[user.username]) {
                userFuncMap[user.username].push(fid);
              } else {
                userFuncMap[user.username] = [fid];
              }
            });

            console.log(userFuncMap);
            //setUserFunctionalitiesMap(userFuncMap);

            setUserFunctionalitiesMap((prevData) => ({
              ...prevData,
              ...userFuncMap,
            }));
            console.log(userFunctionalitiesMap);
          }
          return response;

          //setUserNumber(response.users);

          // console.log(userNumber);
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
  };

  const handleCloseModal = () => {
    setShowResultsModal(false); // Close the modal
    setApiResults([]); // Clear the API results when modal is closed
  };

  const divStyle = {
    color: "white",
  };

  const t = {
    width: "100%",
  };

  return (
    <div className="container">
      {isSpin ? (
        <div className="preloader">
          <img className="w30" src={loader} />
        </div>
      ) : (
        ""
      )}
      <Commonheader pagetitle={"Element Permission"} props={props} />
      <br />
      <br />
      <div className="dropdowns-row">
        <div className="dropdown">
          <label htmlFor="app">App:</label>
          <select id="app" onChange={handleAppChange} value={selectedApp}>
            <option value="">Select App</option>
            <option value="Collection_UI">Collection_UI</option>
          </select>
        </div>

        <div className="dropdown">
          <label htmlFor="component">Component:</label>

          <Select
            style={t}
            showSearch
            onChange={handleComponentChange}
            disabled={!Object.keys(components).length}
            placeholder="Select Component               "
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option && option.label
                ? option.label.toLowerCase()
                : ""
              ).includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA && optionA.label
                ? optionA.label.toLowerCase()
                : ""
              ).localeCompare(
                optionB && optionB.label ? optionB.label.toLowerCase() : ""
              )
            }
            options={
              components &&
              components.map((key) => {
                return {
                  label: key.component_name,
                  value: key.component_id,
                };
              })
            }
          />
        </div>

        <div className="dropdown">
          <label htmlFor="app" hidden={!selectedApp || !selectedComponentId}>
            Element:
          </label>
          <select
            value={filterElementId}
            onChange={handleFilterChange}
            hidden={!selectedApp || !selectedComponentId}
          >
            <option value="">Filter by Element Name</option>

            {/* {functionalities.flatMap((func) =>
                func.element_permission.map((element) => (
                  <option key={element.element_id} value={element.element_id}>
                    {element.element_name}
                  </option>
                ))
              )} */}

            {/* {

                
                uniqueElementMap.length>0 && uniqueElementMap.map((element) => (
                  <option key={element.element_id} value={element.element_id}>
                    {element.element_name}
                  </option>
                ))
              }  */}
            {filteredFunctionalities.flatMap((func) =>
              func.element_permission
                .filter(
                  (element) =>
                    !uniqueElementIds.has(element.element_id) &&
                    uniqueElementIds.add(element.element_id)
                )
                .map((element) => (
                  <option key={element.element_id} value={element.element_id}>
                    {element.element_name}
                  </option>
                ))
            )}
          </select>
        </div>

        <div className="dropdown">
          <label htmlFor="app" hidden={!selectedApp || !selectedComponentId}>
            Users:
          </label>
          <select
            value={filterUserId}
            onChange={handleUsersFilterChange}
            hidden={!selectedApp || !selectedComponentId}
          >
            <option value="">Filter by Username</option>

            {console.log(userFunctionalitiesMap)}
            {console.log(Object.keys(userFunctionalitiesMap).length)}

            {/* {Object.keys(userFunctionalitiesMap).length >0 && userFunctionalitiesMap.flatMap((func) =>
         
     
        <option key={func.userid} value={func.userid}>
          {func.userid}
          
        </option>
        // console.log("bjvir")
    
  )
} */}
            {Object.keys(userFunctionalitiesMap).map((k) => (
              <option key={k} value={k}>
                {k}
              </option>
            ))}
          </select>
        </div>
      </div>

      {selectedApp && selectedComponentId && functionalities.length > 0 && (
        <div className="functionalities">
          {/* { functionalities.filter((func) =>
        func.element_permission.some((element) => element.element_id === filterElementId)
      )
.map((functionality) => (
            <div className="jumbotron" key={functionality.functionality_id}>
              <div
                className="jumbotron-header"
                onClick={() =>
                  setFunctionalities((prev) =>
                    prev.map((func) =>
                      func.functionality_id === functionality.functionality_id ? { ...func, isOpen: !func.isOpen } : func
                    )
                  )
                } */}

          {functionalities
            .filter(
              (func) =>
                filterElementId
                  ? func.element_permission.some(
                      (element) => element.element_id === filterElementId
                    )
                  : true // If filterElementId is null, include all functionalities
            )
            .map((functionality) => (
              <div className="jumbotron" key={functionality.functionality_id}>
                <div
                  key={divKey}
                  className="jumbotron-header"
                  onClick={() =>
                    setFunctionalities((prev) =>
                      prev.map((func) =>
                        func.functionality_id === functionality.functionality_id
                          ? { ...func, isOpen: !func.isOpen } // Toggle isOpen for the clicked functionality
                          : func
                      )
                    )
                  }
                >
                  <div className="function-name">
                    <h3 style={divStyle}>{functionality.function_name}</h3>
                  </div>

                  {/* <button
                      className=""
                      onClick={(e) => { e.stopPropagation(); handleFunctionalityUpdate(functionality.functionality_id,"A")}}
                    >
                      Enable
                    </button>

                    <button
                      className=""
                      onClick={(e) => { e.stopPropagation(); handleFunctionalityUpdate(functionality.functionality_id,"X")}}
                    >
                     Disable
                    </button> */}

                  <div className="function-name">
                    <ToggleSwitch
                      className="api-button"
                      functionalityId={functionality.functionality_id}
                      isEnabled={functionality.status == "A"}
                      onToggle={(id, state) =>
                        handleFunctionalityUpdate(id, state)
                      }
                    />
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="api-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFunctionalityButtonClick(
                          functionality.functionality_id
                        );
                      }}
                      disabled={functionality.status == "X"}
                      title={
                        functionality.status == "X"
                          ? "Functionality has been disabled permanently"
                          : ""
                      }
                    >
                      Users
                    </button>
                  </div>
                </div>
                {functionality.isOpen && (
                  <div className="jumbotron-content">
                    {functionality.element_permission.map((element) => (
                      <div className="element" key={element.element_id}>
                        <p>
                          Element: {element.element_name} &nbsp;&nbsp;{" "}
                          <i
                            className="info-icon"
                            onClick={() =>
                              handleElementInfoClick(element.element_id)
                            }
                          >
                            i
                          </i>
                        </p>

                        <div className="options">
                          <div className="radio-buttons">
                            <div className="t">
                              <input
                                type="radio"
                                id={`enable-${element.element_id}`}
                                value="E"
                                checked={
                                  elementPermissions[
                                    functionality.functionality_id
                                  ] &&
                                  elementPermissions[
                                    functionality.functionality_id
                                  ][element.element_id] === "E"
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    functionality.functionality_id,
                                    element.element_id,
                                    "E"
                                  )
                                }
                              />
                              <label htmlFor={`enable-${element.element_id}`}>
                                &nbsp;Enable
                              </label>
                            </div>
                            <div className="t">
                              <input
                                type="radio"
                                id={`disable-${element.element_id}`}
                                value="D"
                                checked={
                                  elementPermissions[
                                    functionality.functionality_id
                                  ] &&
                                  elementPermissions[
                                    functionality.functionality_id
                                  ][element.element_id] === "D"
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    functionality.functionality_id,
                                    element.element_id,
                                    "D"
                                  )
                                }
                              />
                              <label htmlFor={`disable-${element.element_id}`}>
                                &nbsp;Disable
                              </label>
                            </div>
                            <div className="t">
                              <input
                                type="radio"
                                id={`hidden-${element.element_id}`}
                                value="H"
                                checked={
                                  elementPermissions[
                                    functionality.functionality_id
                                  ] &&
                                  elementPermissions[
                                    functionality.functionality_id
                                  ][element.element_id] === "H"
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    functionality.functionality_id,
                                    element.element_id,
                                    "H"
                                  )
                                }
                              />
                              <label htmlFor={`hidden-${element.element_id}`}>
                                &nbsp;Hide
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <br />
                    {functionality.element_permission.length > 0 && (
                      <div className="but">
                        <button
                          onClick={() =>
                            handleUpdatePermissions1(
                              functionality.functionality_id
                            )
                          }
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      )}

      <ResultsModal
        show={showResultsModal}
        onHide={() => {
          setShowResultsModal(false); // Close the modal
          setApiResults([]); // Clear the API results when modal is closed
        }}
        results={apiResults}
        className="custom-modal"
      />

      <StatusModel
        show={showStatusModal}
        onHide={() => setShowStatusModel(false)}
        result={apiStatus}
      />

      <ElementInfoModel
        show={showElementInfoModal}
        onHide={() => setShowElementInfoModal(false)}
        elementInfo={elementInfo}
      />

      <Modal show={showModal} onHide={cancelUpdatePermissions}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalInfo ? modalInfo.title : "Confirm Update"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalInfo ? modalInfo.message : ""}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelUpdatePermissions}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdatePermissions(selectedFunctionId)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWarningModal} onHide={handleModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalInfo
              ? modalInfo.title
              : "Do you want to disable the functionality for permanently"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalInfo ? modalInfo.message : ""}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancel}>
            No
          </Button>
          <Button variant="primary" onClick={handleWarningModalConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmationModal} onHide={handleModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalInfo
              ? modalInfo.title
              : "Do you want to disable the functionality for permanenty"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalInfo ? modalInfo.message : ""}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancel}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmationModalConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 
    
      <label>
        <input
          type="checkbox"
          checked={isToggled}
          onChange={handleToggleChange}
        />
        Toggle Switch
      </label> */}
    </div>
  );
};

export default App;
