// src/components/ElementInfoModal.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ElementInfoModal = ({ show, onHide, elementInfo }) => {
    const [count,setCount]=useState(1);
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Element Information</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
      <p>
      This Element is present in the below given Functionalities : </p>
        {elementInfo.length > 0 ? (
          <ul>

            {elementInfo.map((info, index) => (
               
              <li key={index}>{index+1 + ". "+info}</li>
             
            ))}
          </ul>
        ) : (
          <p>No additional information available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementInfoModal;
