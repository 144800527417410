import React, { useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import UserExperior from "user-experior-web";
import CryptoJS from "crypto-js";

const AES_ENCRYPTION_KEY = process.env.REACT_APP_AES_SECRET_KEY;
const encryptFlag = process.env.REACT_APP_ENCRYPT_FLAG == '1'
const AES_BLOCK_SIZE = 16;

export default function useAxios(props) {
  const [loading, setLoading] = useState(false);

  const sendRequest = async (config, action) => {
    try {
      setLoading(true);
      config.headers["api-token"] = localStorage.getItem("access_token");

      const result = await UseJWTToken(config);

      if (typeof action === "function") {
        action(result)
      }

      return result
    } catch (error) {
      console.error("Request error:", error);
      throw error
    } finally {
      setLoading(false);
    }
  };

  return [loading, sendRequest];
}

async function UseJWTToken(config) {
  return await isTokenExpired(config);
}

async function isTokenExpired(config) {
  const token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  console.log("token->", token);
  console.log("refresh_token->", refresh_token);

  if (token) {
    var decoded = jwt_decode(token);
    console.log("decoded->", decoded);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      return await generate_token(config);
    }
  }
  return await encrypt_and_call_api(config);
}

async function generate_token(config) {
  const formdata = new FormData();

  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      if(encryptFlag){
        const data = {
          refresh_token: Cookies.get("refresh_token"),
        };
        const encryptedData = encrypt_data_aes(JSON.stringify(data));
        formdata.append("encrypted_data", encryptedData);
      }
      else{
        formdata.append("refresh_token", Cookies.get("refresh_token"));
      }
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + "/get/access/token",
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          const data3 = await response.json();
          localStorage.setItem("access_token", data3.access_token);
          config.headers["api-token"] = data3.access_token;
          return await encrypt_and_call_api(config);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log(error);
      }
    }
  } else{
    Cookies.remove("api_token");
    localStorage.clear();
    window.location.href = "/";
  }
}

async function encrypt_and_call_api(config) {
  if(encryptFlag){
    config.url = encrypt_get_params(config.url);
    let contentType = config.headers ? config.headers["Content-Type"] : undefined;
    
    if (!contentType && config.data) {
      contentType = get_content_type(config.data);
    }
  
    if (config.data) {
      const encryptedBody = await encrypt_post_data(config.data, contentType);
  
      if(contentType === 'application/json'){
        const data = {
          encrypted_data: encryptedBody
        }
        config.data = JSON.stringify(data)
      }
      else{
        const formData = new FormData();
        formData.append("encrypted_data", encryptedBody);
        config.data = formData
      }
  
      if(config.headers) {
        config.headers["Content-Type"] = config.headers["Content-Type"] || contentType
        if(contentType === 'multipart/form-data') delete config.headers["Content-Type"]
      }
    }
  }

  try {
    const response = await axios(config);
    if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
      const ue = new UserExperior();
      let urlName = config.url.split('/');
      urlName = urlName[urlName.length - 1]
          ue.startRecording(Cookies.get('parameter_value'), {
            sessionReplay: { 
                maskAllInputs: true,
                maskInputOptions: {
                    password: true,
                    email: true,
                    tel: true,
                    color: false,
                    date: false,
                    'datetime-local': false,
                    month: false,
                    number: false,
                    range: false,
                    search: false,
                    text: true,
                    time: false,
                    url: false,
                    week: false,
                    textarea: false,
                    select: false,
                }
            }
          });
          ue.setUserIdentifier(`${localStorage.getItem("in_username")}_${process.env.REACT_APP_ENVIRONMENT}`);
          // ue.setUserIdentifier(localStorage.getItem("in_userid"));
          ue.logEvent(urlName, {
            'headers': JSON.stringify(config.headers),
            'method': config.method,
            'url': config.url
          });
          ue.logEvent('response', {
            'headers': JSON.stringify(config.headers),
            'status': config.status
          });
    }
    return response.data;
  } catch (error) {
    console.error("API Call Error:", error);
    throw error;
  }
}

const get_content_type = (data) => {
  if (data instanceof FormData) {
    return 'multipart/form-data';
  } else if (typeof data === 'object') {
    return 'application/json'
  } else if (typeof data === 'string') {
    if(isvalidjson(data)) return 'application/json'
    else if(isUrlEncoded(data)) return 'application/x-www-form-urlencoded';
    else return 'text/plain'
  }
  return undefined
};

const isUrlEncoded = (data) => {
  try {
    return decodeURIComponent(data) !== data;
  } catch (e) {
    return false;
  }
};

const isvalidjson = (data) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
}

const encrypt_get_params = (url) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);

  params.forEach((value, key) => {
    const encryptedValue = encrypt_data_aes(value);
    params.set(key, encryptedValue);
  });

  urlObj.search = params.toString();

  return urlObj.toString();
};

const encrypt_post_data = async (bodySrc, contentType) => {
  if (contentType && contentType.includes("application/json")) {
    try {
      const parsedBody = typeof bodySrc === "string" ? JSON.parse(bodySrc) : bodySrc;
      return encrypt_data_aes(JSON.stringify(parsedBody));
    } catch (error) {
      console.error("Failed to parse JSON body:", error);
      throw new Error("Invalid JSON body");
    }
  } else if (contentType && contentType.includes("multipart/form-data")) {
    const jsonObject = {};
    const fileConversionPromises = []
    const filesConverted = []
    for (const [key, value] of bodySrc.entries()) {
      if (value instanceof File) {
        fileConversionPromises.push(
          file_to_base64(value).then(base64Data => {
            const mime_type = value.type || 'application/octet-stream';
            filesConverted.push({
              content: base64Data,
              filename: value.name,
              filetype: mime_type
            })
          })
        );
      } else {
        jsonObject[key] = value;
      }
    }
    await Promise.all(fileConversionPromises);
    if(filesConverted.length > 0){
      jsonObject["file"] = filesConverted
    }

    return encrypt_data_aes(JSON.stringify(jsonObject));
  } else if (contentType && contentType.includes("application/x-www-form-urlencoded")) {
    const params = new URLSearchParams(bodySrc);
    const jsonConvertedBody = {};
    params.forEach((value, key) => {
      jsonConvertedBody[key] = value;
    });
    return encrypt_data_aes(JSON.stringify(jsonConvertedBody));
  } else {
    return encrypt_data_aes(bodySrc);
  }
};

function encrypt_data_aes(data) {
  const keyBytes = CryptoJS.enc.Base64.parse(AES_ENCRYPTION_KEY);
  const iv = CryptoJS.lib.WordArray.random(AES_BLOCK_SIZE);

  const encrypted = CryptoJS.AES.encrypt(data, keyBytes, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const combinedData = iv.concat(encrypted.ciphertext);
  const hexEncoded = CryptoJS.enc.Hex.stringify(combinedData);

  return hexEncoded;
}

const file_to_base64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
